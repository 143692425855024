import classNames from "classnames";
import React from "react";

interface Props {
	label: string;
	defaultValue: string;
	placeholder?: string;
	readOnly?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = ({
	label,
	placeholder,
	defaultValue,
	readOnly,
	onChange,
}: Props) => {
	return (
		<label className="grid gap-1 text-lg dark:text-gray-400">
			{label}
			<input
				type="text"
				defaultValue={defaultValue}
				placeholder={placeholder}
				onChange={onChange}
				className={classNames([
					"py-2 px-4 outline-none rounded-md border-2 truncate",
					"border-gray-300 text-gray-700",
					"focus:border-blue-700",
					"dark:text-gray-400 dark:placeholder-gray-600 dark:bg-gray-800 dark:border-gray-700",
					"transition-all ease duration-300",
				])}
				readOnly={readOnly}
			/>
		</label>
	);
};

export default TextInput;
