import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import MembersList from "../../Components/MembersList";
import VotingKeyboard from "../../Components/VotingKeyboard";

interface UrlParams {
	id: string;
	member: string;
}

const VotingSessionAsMember = () => {
	const { id, member } = useParams<UrlParams>();
	const db = firebase.firestore();
	const [state, setState] = useState({
		projectName: "",
		name: "",
		votingSessionStarted: false,
		adminHasVoted: false,
		adminVote: "",
		revealAllVotes: false,
	});
	const [members, setMembers] = useState<any>([]);
	const [user, setUser] = useState<any>({});

	useEffect(() => {
		db.collection("sessions")
			.doc(id)
			.onSnapshot((snapshot) => {
				if (snapshot) {
					const data = snapshot.data();
					return setState({
						projectName: data?.projectName,
						name: data?.name,
						votingSessionStarted: data?.votingSessionStarted,
						adminHasVoted: data?.adminHasVoted,
						adminVote: data?.adminVote,
						revealAllVotes: data?.revealAllVotes,
					});
				}
			});

		db.collection("sessions")
			.doc(id)
			.collection("members")
			.onSnapshot((snapshot) => {
				let members: any = [];

				snapshot.forEach((entry) => {
					members.push(entry.data());
				});

				return setMembers(members);
			});

		db.collection("sessions")
			.doc(id)
			.collection("members")
			.doc(member)
			.onSnapshot((snapshot) => {
				return setUser(snapshot.data());
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClick = (e: any) => {
		db.collection("sessions")
			.doc(id)
			.collection("members")
			.doc(member)
			.update({ hasVoted: true, vote: e.target.value });
	};

	return (
		<div className="grid gap-4">
			<h1 className="text-3xl">Project: {state.projectName}</h1>

			<MembersList
				admin={{ name: state.name, vote: state.adminVote }}
				members={members}
				revealVotes={state.revealAllVotes}
				votingSessionIsStarted={state.votingSessionStarted}
			/>

			{state.votingSessionStarted ? (
				<VotingKeyboard
					handleClick={(e) => handleClick(e)}
					clickedValue={user.vote}
				/>
			) : (
				<h2 className="text-base">The voting will start soon.</h2>
			)}
		</div>
	);
};

export default VotingSessionAsMember;
