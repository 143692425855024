import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import firebase from "firebase/app";
import "firebase/firestore";
import MembersList from "../../Components/MembersList";
import VotingKeyboard from "../../Components/VotingKeyboard";
import InviteLink from "../../Components/InviteLink";

interface UrlParams {
	id: string;
}

const VotingSession = () => {
	const { id } = useParams<UrlParams>();
	const db = firebase.firestore();
	const [state, setState] = useState({
		projectName: "",
		name: "",
		votingSessionStarted: false,
		adminHasVoted: false,
		adminVote: "",
		revealAllVotes: false,
	});
	const [members, setMembers] = useState<any>([]);

	const inviteLink =
		// eslint-disable-next-line no-restricted-globals
		location.hostname === "localhost"
			? `localhost:3000/j/${id}`
			: `https://vope.app/j/${id}`;

	useEffect(() => {
		const collectionRef = db.collection("sessions");
		const votingSessionRef = collectionRef.doc(id);
		const membersRef = collectionRef.doc(id).collection("members");

		votingSessionRef.onSnapshot((snapshot) => {
			if (snapshot.exists) {
				const data = snapshot.data();
				return setState({
					projectName: data?.projectName,
					name: data?.name,
					votingSessionStarted: data?.votingSessionStarted,
					adminHasVoted: data?.adminHasVoted,
					adminVote: data?.adminVote,
					revealAllVotes: data?.revealAllVotes,
				});
			}
		});

		membersRef.onSnapshot((snapshot) => {
			let members: any = [];

			snapshot.forEach((entry) => {
				members.push(entry.data());
			});

			return setMembers(members);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStartStopVoting = () => {
		const members = db
			.collection("sessions")
			.doc(id)
			.collection("members")
			.get();

		db.collection("sessions")
			.doc(id)
			.update({
				votingSessionStarted: !state.votingSessionStarted,
				adminHasVoted: false,
				adminVote: "",
				revealAllVotes: false,
			})
			.then(() => {
				members.then((result) => {
					result.docs.forEach((doc) => {
						db.collection("sessions")
							.doc(id)
							.collection("members")
							.doc(doc.id)
							.update({ hasVoted: false, vote: "" });
					});
				});
			});
	};

	const handleAdminVote = (e: any) => {
		return db.collection("sessions").doc(id).update({
			adminHasVoted: true,
			adminVote: e.target.value,
			votingSessionStarted: false,
			revealAllVotes: true,
		});
	};

	const renderAdminVoting = () => {
		const membersCount = members.length;
		const votedCount = [];

		members.map((member: any) => {
			if (member.hasVoted) {
				votedCount.push(member.hasVoted);
			}
			return null;
		});

		if (
			state.votingSessionStarted &&
			membersCount > 0 &&
			membersCount === votedCount.length
		) {
			return (
				<VotingKeyboard
					handleClick={(e) => {
						handleAdminVote(e);
					}}
					clickedValue={state.adminVote}
				/>
			);
		}
	};

	return (
		<div className="grid gap-4">
			<h1 className="text-3xl">Project: {state.projectName}</h1>

			<InviteLink inviteLink={inviteLink} label="Invite your Teammates" />

			<MembersList
				sessionId={id}
				isAdmin
				admin={{ name: state.name, vote: state.adminVote }}
				members={members}
				revealVotes={state.revealAllVotes}
				votingSessionIsStarted={state.votingSessionStarted}
			/>

			{renderAdminVoting()}

			{members.length > 0 && (
				<button
					className={classnames([
						state.votingSessionStarted
							? "bg-red-700 hover:bg-red-900"
							: "bg-blue-700 hover:bg-blue-900",
						"p-3 rounded-md text-white",
					])}
					onClick={handleStartStopVoting}
				>
					{state.votingSessionStarted ? "Stop the Voting" : "Start Voting"}
				</button>
			)}
		</div>
	);
};

export default VotingSession;
