import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import "firebase/firestore";
import { useHistory, useParams } from "react-router-dom";
import TextInput from "../../Components/TextInput";

interface UrlParams {
	id: string;
}

const Join = () => {
	let history = useHistory();
	const db = firebase.firestore();
	const { id } = useParams<UrlParams>();
	const [state, setState] = useState({
		name: "",
		uid: uuidv4(),
	});

	return (
		<div className="grid gap-8">
			<form
				className="grid gap-4"
				onSubmit={(e) => {
					e.preventDefault();

					db.collection("sessions")
						.doc(id)
						.collection("members")
						.doc(state.uid)
						.set(state)
						.then(() => {
							history.push(`/v/${id}/${state.uid}`);
						})
						.catch((e) => console.error(e));
				}}
			>
				<TextInput
					label="What's your Name?"
					defaultValue={state.name}
					placeholder="Steve"
					onChange={(e) => setState({ ...state, name: e.target.value })}
				/>

				<button type="submit" className="bg-blue-700 p-3 rounded-md text-white">
					Join the voting session
				</button>
			</form>
		</div>
	);
};

export default Join;
