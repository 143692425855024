const AppHeader = () => {
	return (
		<header className="flex justify-between items-center mb-8">
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="32" height="32" rx="10" fill="#F65B23" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.7263 8.77655C10.4186 8.00739 9.54566 7.63329 8.7765 7.94098C8.00733 8.24867 7.63323 9.12164 7.94092 9.89081L9.54092 13.8905C9.84862 14.6596 10.7216 15.0337 11.4908 14.726C12.2599 14.4183 12.634 13.5454 12.3263 12.7762L10.7263 8.77655ZM24.0483 9.9185C24.3712 9.15563 24.0146 8.27537 23.2518 7.95238C22.4889 7.62939 21.6086 7.98599 21.2857 8.74886L15.9091 21.4478L14.7198 18.5718C14.4032 17.8062 13.526 17.4423 12.7604 17.7588C11.9949 18.0754 11.6309 18.9526 11.9475 19.7182L14.5066 25.9069C14.7377 26.4657 15.2817 26.8311 15.8865 26.8337C16.4912 26.8362 17.0383 26.4754 17.2741 25.9185L24.0483 9.9185Z"
					fill="white"
				/>
			</svg>

			<p>Story estamating made easy.</p>
		</header>
	);
};

export default AppHeader;
