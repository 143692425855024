import VotingButton from "./VotingButton";

interface VotingKeyboardProps {
	clickedValue: string;
	handleClick: (e: any) => void;
}

const VotingKeyboard = ({ handleClick, clickedValue }: VotingKeyboardProps) => {
	const votingNumbers = [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 100];

	return (
		<div className="grid gap-2">
			<h2 className="text-lg dark:text-gray-400">Please take your voting</h2>
			<div className="grid grid-cols-3 gap-2">
				{votingNumbers.map((votingNumber, key) => {
					return (
						<VotingButton
							key={key}
							value={votingNumber}
							handleClick={handleClick}
							clickedValue={clickedValue}
							label={votingNumber === 100 ? "8+" : votingNumber.toString()}
							appereance="primary"
						/>
					);
				})}
			</div>
			<div>
				<VotingButton
					value={0}
					handleClick={handleClick}
					clickedValue={clickedValue}
					label="I can't estimate"
					appereance="sencondary"
				/>
			</div>
		</div>
	);
};

export default VotingKeyboard;
