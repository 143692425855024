import classNames from "classnames";
import { useState } from "react";

interface Props {
	inviteLink: string;
	label: string;
}

const InviteLink = ({ inviteLink, label }: Props) => {
	const [state, setState] = useState(false);

	const copyToClipboard = () => {
		const link = document.getElementById("link") as HTMLInputElement;
		link?.focus();
		link?.select();
		document.execCommand("copy");
		link?.blur();
		setState(true);

		setTimeout(() => {
			setState(false);
		}, 1000);
	};

	return (
		<label className="grid gap-1 text-base dark:text-gray-400">
			{label}

			<div className="relative flex">
				<input
					type="text"
					defaultValue={inviteLink}
					readOnly
					id="link"
					className={classNames([
						"flex-grow py-2 px-4 outline-none rounded-md border-2 truncate",
						"border-gray-300 text-gray-700",
						"focus:border-blue-700",
						"dark:text-gray-400 dark:placeholder-gray-600 dark:bg-gray-800 dark:border-gray-700",
						"transition-all ease duration-300 appearance-none select-none",
					])}
				/>
				<button
					onClick={copyToClipboard}
					className="absolute z-10 right-0 p-2 m-1 text-gray-400 rounded-sm"
				>
					{state ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polyline points="20 6 9 17 4 12"></polyline>
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
							<path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
						</svg>
					)}
				</button>
			</div>
		</label>
	);
};

export default InviteLink;
