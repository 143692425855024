import classNames from "classnames";
import { useEffect, useState } from "react";

interface VotingButtonProps {
	value: number;
	label: string | undefined;
	clickedValue: string;
	appereance: "primary" | "sencondary";
	handleClick: (e: any) => void;
}

const VotingButton = ({
	value,
	label,
	clickedValue,
	appereance,
	handleClick,
}: VotingButtonProps) => {
	const [isClicked, setIsClicked] = useState(false);

	useEffect(() => {
		if (clickedValue === value.toString()) return setIsClicked(true);
		return setIsClicked(false);
	}, [value, clickedValue]);

	return (
		<button
			type="button"
			className={classNames(
				"w-full text-white p-3 rounded-md text-xl font-bold last:col-span-full",
				isClicked ? "bg-green-400" : "bg-blue-700 hover:bg-blue-900",
				appereance === "sencondary" && "bg-pink-700 hover:bg-pink-900"
			)}
			onClick={handleClick}
			value={value}
		>
			{label}
		</button>
	);
};

export default VotingButton;
