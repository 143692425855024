import Avatar from "boring-avatars";
import firebase from "firebase/app";
import "firebase/firestore";

interface Props {
	isAdmin?: boolean;
	sessionId?: string;
	admin: { name: string; vote: string };
	members: [];
	revealVotes: boolean;
	votingSessionIsStarted: boolean;
}

const MembersList = ({
	isAdmin,
	sessionId,
	admin,
	members,
	revealVotes,
	votingSessionIsStarted,
}: Props) => {
	const db = firebase.firestore();
	const getLabel = (value: string) => {
		if (value === "100") return "8+";
		if (value === "0") return "can't estimate";
		return value;
	};

	const deleteMember = (uid: string) => {
		db.collection("sessions")
			.doc(sessionId)
			.collection("members")
			.doc(uid)
			.delete();
	};

	const renderAdmin = () => {
		return (
			<li className="flex w-full items-center justify-between border-b-2 py-2 px-4 dark:border-gray-700 last:border-b-0">
				<div className="flex items-center">
					<Avatar
						size={24}
						name={admin.name}
						variant="marble"
						colors={["#A3A948", "#EDB92E", "#F85931", "#CE1836", "#009989"]}
					/>
					<span className="ml-4">{admin.name}</span>
				</div>

				{votingSessionIsStarted && (
					<span className="text-center bg-gray-600 text-gray-400 px-2 rounded-md text-base font-semibold uppercase">
						waiting
					</span>
				)}

				{revealVotes && (
					<span className="w-auto text-center bg-green-500 text-white px-2 rounded-md text-base font-semibold uppercase">
						{getLabel(admin.vote)}
					</span>
				)}
			</li>
		);
	};

	return (
		<label className="text-lg dark:text-gray-400">
			<ul className="grid bg-gray-100 border-2 dark:border-gray-700 dark:bg-gray-800 rounded-md">
				{renderAdmin()}

				{members.length > 0 &&
					members.map(
						(
							member: {
								name: string;
								vote: string;
								hasVoted: boolean;
								uid: string;
							},
							key: number
						) => (
							<li
								key={key}
								className="flex w-full items-center justify-between border-b-2 py-2 px-4 dark:border-gray-700 last:border-b-0"
							>
								<div className="flex items-center">
									<Avatar
										size={24}
										name={member.name}
										variant="marble"
										colors={[
											"#A3A948",
											"#EDB92E",
											"#F85931",
											"#CE1836",
											"#009989",
										]}
									/>
									<span className="ml-4">{member.name}</span>
								</div>

								<div>
									{isAdmin && (
										<button onClick={() => deleteMember(member.uid)}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											>
												<polyline points="3 6 5 6 21 6"></polyline>
												<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
											</svg>
										</button>
									)}

									{votingSessionIsStarted && !member.hasVoted && (
										<span className="text-center bg-gray-600 text-gray-400 px-2 rounded-md text-base font-semibold uppercase ml-4">
											thinking
										</span>
									)}

									{member.hasVoted && !revealVotes && (
										<span className="text-center bg-green-500 text-white px-2 rounded-md text-base font-semibold uppercase ml-4">
											done
										</span>
									)}

									{revealVotes && (
										<span className="w-auto text-center bg-green-500 text-white px-2 rounded-md text-base font-semibold uppercase ml-4">
											{getLabel(member.vote)}
										</span>
									)}
								</div>
							</li>
						)
					)}
			</ul>
		</label>
	);
};

export default MembersList;
