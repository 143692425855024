import { useState } from "react";
import ShortUniqueId from "short-unique-id";
import firebase from "firebase/app";
import "firebase/firestore";
import { useHistory } from "react-router-dom";
import TextInput from "../../Components/TextInput";

const CreateSession = () => {
	let history = useHistory();
	const db = firebase.firestore();

	const uid = new ShortUniqueId({ length: 4 });
	const sessionId = uid.randomUUID().toLowerCase();

	const [setup, setSetup] = useState({
		name: "",
		projectName: "",
		timeStamp: Date.now(),
		votingSessionStarted: false,
	});

	return (
		<div className="grid gap-8">
			<h1 className="text-3xl dark:text-gray-400">
				Create your voting session.
			</h1>
			<form
				className="grid gap-4"
				onSubmit={(e) => {
					e.preventDefault();

					db.collection("sessions")
						.doc(sessionId)
						.set(setup)
						.then(() => {
							history.push(`/s/${sessionId}`);
						})
						.catch((e) => console.error(e));
				}}
			>
				<TextInput
					label="What's your Name?"
					defaultValue={setup.name}
					placeholder="Steve"
					onChange={(e) => setSetup({ ...setup, name: e.target.value })}
				/>

				<TextInput
					label="What's the name of your project?"
					defaultValue={setup.projectName}
					placeholder="Vope"
					onChange={(e) => setSetup({ ...setup, projectName: e.target.value })}
				/>

				<button
					type="submit"
					className="bg-blue-700 ring-current p-3 rounded-md text-white font-medium"
				>
					Create voting session
				</button>
			</form>
		</div>
	);
};

export default CreateSession;
