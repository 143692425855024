import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";
import "./index.css";

import CreateSession from "./Views/ProductOwner/CreateSession";
import VotingSession from "./Views/ProductOwner/VotingSession";
import JoinSessionAsMember from "./Views/Member/JoinSessionAsMember";
import VotingSessionAsMember from "./Views/Member/VotingSessionAsMember";
import AppHeader from "./Components/AppHeader";

const firebaseConfig = {
	apiKey: "AIzaSyD5rebwwxUtbQMeJ4ybVAZBpOOoBvNFHVg",
	authDomain: "vope-850f5.firebaseapp.com",
	projectId: "vope-850f5",
	storageBucket: "vope-850f5.appspot.com",
	messagingSenderId: "664816957206",
	appId: "1:664816957206:web:dd3185eddec3e1d3cd0237",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
	db.useEmulator("localhost", 8080);
}

function App() {
	return (
		<div className="h-screen dark:bg-gray-900 dark:text-gray-500 text-gray-600">
			<div className="container max-w-screen-lg mx-auto dark:bg-gray-900 pt-6 md:pt-12">
				<AppHeader />
				<Router>
					<Route exact path="/">
						<CreateSession />
					</Route>
					<Route exact path="/s/:id">
						<VotingSession />
					</Route>
					<Route exact path="/j/:id">
						<JoinSessionAsMember />
					</Route>
					<Route exact path="/v/:id/:member">
						<VotingSessionAsMember />
					</Route>
				</Router>
			</div>
		</div>
	);
}

export default App;
